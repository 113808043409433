/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';

import { Button, Input } from '../../../forms';
import {
  formContainerStyles,
  contentStyles,
  formTitleStyles,
  formDescriptionStyles,
  formStyles,
  notificationMessageStyles,
  submitButtonStyles,
} from './MainForm.styles';

const MainForm = ({
  email,
  onChange,
  handleResetPassword,
  formLoading,
  resetPasswordStatus,
}) => (
  <div css={formContainerStyles}>
    {!resetPasswordStatus ? (
      <div css={contentStyles}>
        <h1 css={formTitleStyles}>lupa password?</h1>
        <p css={formDescriptionStyles}>
          silakan masukkan alamat email kamu di bawah ini
        </p>

        <form css={formStyles} onSubmit={handleResetPassword}>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            iconName="envelope"
            placeholder="email"
          />
          <Button styles={submitButtonStyles}>
            {formLoading ? 'menunggu...' : 'minta reset password'}
          </Button>
        </form>
      </div>
    ) : (
      <p css={notificationMessageStyles}>
        Kami telah mengirimkan verifikasi perubahan password ke emailmu. Silakan
        untuk mengecek email anda.
      </p>
    )}
  </div>
);

MainForm.propTypes = {
  email: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleResetPassword: PropTypes.func.isRequired,
  formLoading: PropTypes.bool.isRequired,
  resetPasswordStatus: PropTypes.bool.isRequired,
};

export default MainForm;
