import { Auth } from 'aws-amplify';

import { specialCharacterTrimmer } from '../../helpers';

const authSignUp = ({ email, password }) => Auth.signUp({
  username: specialCharacterTrimmer(email),
  password,
  attributes: {
    email,
  },
  validationData: [],
});

export default authSignUp;
