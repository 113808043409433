/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';

import kodeLogo from '../../assets/images/logo/kode-logo-dark.png';
import {
  layoutStyles,
  bodyStyles,
  leftContainerStyles,
  logoStyles,
  rightContainerStyles,
  backgroundImageStyles,
} from './Layout.styles';

const Layout = ({ children }) => (
  <div css={layoutStyles}>
    <div css={bodyStyles}>
      <div css={leftContainerStyles}>
        <img src={kodeLogo} css={logoStyles} alt="KODE-logo" />
        <div css={backgroundImageStyles} />
      </div>
      <div css={rightContainerStyles}>{children}</div>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
