/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  containerStyles,
  titleStyles,
  descriptionStyles,
} from './SuccessPage.styles';

const SuccessPage = () => (
  <div css={containerStyles}>
    <h1 css={titleStyles}>terima kasih telah bergabung bersama KODE</h1>
    <p css={descriptionStyles}>email konfirmasi anda telah kami kirimkan</p>
  </div>
);

export default SuccessPage;
