import { css } from '@emotion/core';

import { colors } from '../../../../../../configs';

export const googleButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 0 0 15px;
  border: 1px solid ${colors.KODE_LIGHT_GREY};
  border-radius: 50%;
  background-color: ${colors.GOOGLE_GREY};
  outline: none;
  cursor: pointer;
`;

export const iconStyles = css`
  margin: 5px 0 0 0;
`;
