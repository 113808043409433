export default {
  BLACK: '#000',
  WHITE: '#fff',
  DANGER: '#dc3545',
  LIGHT_GREY: '#f8f8f8',
  LIGHT_GREY_2: '#ebeced',
  GREY: '#9ba2a9',
  KODE_RED: '#ff6360',
  KODE_PURPLE: '#645aff',
  KODE_LIGHT_GREY: '#e8edf2',
  KODE_GREY: '#f6f7fA',
  KODE_DARK_GREY: '#9ba2a9',
  KODE_CHARCOAL: '#394654',
  KODE_TOPAZ: '#18b7bc',
  KODE_LIGHT_TOPAZ: '#e7f8f8',
  FACEBOOK_BLUE: '#3b5998',
  GOOGLE_GREY: '#f5f7fa',
};
