import axios from 'axios';

const kodeCheckEmail = async ({ email }) => {
  const res = await axios({
    url: `${process.env.REACT_APP_KODE_API}/users/${email}`,
    method: 'get',
  });

  return res;
};

export default kodeCheckEmail;
