/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

import { Button, Input } from '../../../forms';
import { FacebookSignIn, GoogleSignIn } from './SocialMedia';
import {
  formContainerStyles,
  contentStyles,
  formTitleStyles,
  formDescriptionStyles,
  formStyles,
  linkContainerStyles,
  linkStyles,
  submitButtonStyles,
  socialMediaSeparatorContainerStyles,
  socialMediaSeparatorStyles,
  socialMediaSeparatorDescriptionStyles,
  socialMediaContainerStyles,
  termsAndConditionContainerStyles,
  termsAndConditionTextStyles,
  termsAndConditionLinkStyles,
} from './MainForm.styles';

const MainForm = ({
  userFormData,
  onChange,
  redirect,
  handleSignIn,
  formLoading,
  setMessages,
}) => {
  const { email, password } = userFormData;

  return (
    <div css={formContainerStyles}>
      <div css={contentStyles}>
        <h1 css={formTitleStyles}>selamat datang kembali!</h1>
        <p css={formDescriptionStyles}>silakan masuk dengan alamat email</p>
        <form css={formStyles} onSubmit={handleSignIn}>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            iconName="envelope"
            placeholder="email"
          />
          <Input
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            iconName="lock"
            placeholder="password"
          />
          <div css={linkContainerStyles}>
            <NavLink to="/reset-password" tabIndex={-1} css={linkStyles}>
              lupa password?
            </NavLink>
          </div>
          <Button styles={submitButtonStyles}>
            {formLoading ? 'menunggu...' : 'masuk'}
          </Button>
          <div css={socialMediaSeparatorContainerStyles}>
            <div css={socialMediaSeparatorStyles} />
            <p css={socialMediaSeparatorDescriptionStyles}>
              atau masuk melalui social media
            </p>
          </div>
          <div css={socialMediaContainerStyles}>
            <FacebookSignIn redirect={redirect} setMessages={setMessages} />
            <GoogleSignIn redirect={redirect} setMessages={setMessages} />
          </div>
          <div css={termsAndConditionContainerStyles}>
            <p css={termsAndConditionTextStyles}>
              saya telah menyetujui &nbsp;
              <a
                href="https://www.kode.id/pages/syarat-dan-ketentuan"
                target="_blank"
                rel="noopener noreferrer"
                css={termsAndConditionLinkStyles}
              >
                terms and condition
              </a>
              &nbsp;dan&nbsp;
              <a
                href="https://www.kode.id/pages/kebijakan-privasi"
                target="_blank"
                rel="noopener noreferrer"
                css={termsAndConditionLinkStyles}
              >
                privacy policy
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

MainForm.propTypes = {
  userFormData: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  redirect: PropTypes.shape({
    status: PropTypes.bool,
    productId: PropTypes.string,
  }).isRequired,
  handleSignIn: PropTypes.func.isRequired,
  formLoading: PropTypes.bool.isRequired,
  setMessages: PropTypes.func.isRequired,
};

export default MainForm;
