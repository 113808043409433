import { css } from '@emotion/core';

import { colors } from '../../../../configs';

export const formContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  margin: 30px 0 0 0;
  padding: 35px 15px;
  border-radius: 10px;
  background-color: ${colors.WHITE};
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 1024px) {
    max-width: 500px;
  }

  @media only screen and (min-width: 1024px) {
    width: 470px;
    max-width: unset;
    padding: 50px 20px;
  }
`;

export const contentStyles = css`
  width: 415px;
`;

export const formTitleStyles = css`
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
  font-size: 20px;
  color: ${colors.KODE_CHARCOAL};

  &:first-letter {
    text-transform: uppercase;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 24px;
  }
`;

export const formDescriptionStyles = css`
  margin: 15px 0 0 0;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  color: ${colors.KODE_CHARCOAL};

  &:first-letter {
    text-transform: uppercase;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 14px;
  }
`;

export const formStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 30px 0 0 0;
`;

export const submitButtonStyles = css`
  margin: 15px 0 0 0;
`;

export const notificationMessageStyles = css`
  margin: 10px 0 0 0;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.KODE_CHARCOAL};

  &:first-letter {
    text-transform: uppercase;
  }
`;
