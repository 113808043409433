import { css } from '@emotion/core';

import { colors } from '../../configs';

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 50px 15px;
  background-color: ${colors.KODE_GREY};
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    padding: 0;
  }
`;
