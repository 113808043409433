import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';

import GoogleButton from './GoogleButton';
import {
  kodeCheckEmail,
  kodeGoogleSignIn,
} from '../../../../../../services/axios';

const GoogleSignIn = ({
  id,
  setSignUpMethod,
  setGoogleLoginResponse,
  setUserFormData,
  setShowUserDataConfirmationStatus,
}) => {
  const googleResponse = (response) => {
    const {
      googleId,
      profileObj: { email, givenName, familyName },
      tokenId,
    } = response;

    kodeCheckEmail({ email })
      .then(() => {
        kodeGoogleSignIn({
          accessToken: tokenId,
          googleId,
          redirect: {
            status: false,
          },
        });
      })
      .catch(() => {
        setSignUpMethod('GOOGLE');
        setGoogleLoginResponse(response);
        setUserFormData((prevState) => ({
          ...prevState,
          firstName: givenName,
          lastName: familyName,
        }));
        setShowUserDataConfirmationStatus(true);
      });
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_APP_ID}
      buttonText="Google"
      onSuccess={googleResponse}
      onFailure={() => {}}
      cookiePolicy="single_host_origin"
      render={(renderProps) => (
        <GoogleButton id={id} onClick={renderProps.onClick} />
      )}
    />
  );
};

GoogleSignIn.propTypes = {
  id: PropTypes.string.isRequired,
  setSignUpMethod: PropTypes.func.isRequired,
  setGoogleLoginResponse: PropTypes.func.isRequired,
  setUserFormData: PropTypes.func.isRequired,
  setShowUserDataConfirmationStatus: PropTypes.func.isRequired,
};

export default GoogleSignIn;
