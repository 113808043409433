import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import FacebookButton from './FacebookButton';
import {
  kodeCheckEmail,
  kodeFacebookSignIn,
} from '../../../../../../services/axios';

const FacebookSignIn = ({ redirect, setMessages }) => {
  const responseFacebook = async (response) => {
    const { accessToken, email, userID } = response;

    await kodeCheckEmail({ email })
      .then(() => {
        kodeFacebookSignIn({
          accessToken,
          facebookId: userID,
          redirect,
        });
      })
      .catch((err) => {
        if (err.response.data.data === null) {
          setMessages([`${email} belum terdaftar.`]);
        }
      });
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields="name, email, picture"
      callback={responseFacebook}
      render={(renderProps) => <FacebookButton onClick={renderProps.onClick} />}
    />
  );
};

FacebookSignIn.propTypes = {
  redirect: PropTypes.shape({
    status: PropTypes.bool,
    productId: PropTypes.string,
  }).isRequired,
  setMessages: PropTypes.func.isRequired,
};

export default FacebookSignIn;
