/** @jsx jsx */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { jsx } from '@emotion/core';
import { Auth } from 'aws-amplify';

import MainForm from '../../components/pages/ChangePassword/MainForm/MainForm';
import Layout from '../../components/layout/Layout';
import kodeLogo from '../../assets/images/logo/kode-logo-dark.png';
import { kodeCheckEmail } from '../../services/axios';
import { fetchBrowserCookies } from '../../helpers';
import {
  containerStyles,
  snackbarStyles,
  messageStyles,
  logoStyles,
} from './ChangePassword.styles';

const ResetPassword = () => {
  const history = useHistory();
  const [signUpMethod, setSignUpMethod] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [userFormData, setUserFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newConfirmationPassword: '',
  });
  const [messages, setMessages] = useState([]);

  const resetState = () => {
    setMessages([]);
  };

  const handleChange = (e) => {
    e.persist();
    setUserFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const validateFormField = () => {
    const { oldPassword, newPassword, newConfirmationPassword } = userFormData;

    if (!oldPassword) {
      setMessages(['Password lama wajib diisi.']);

      return false;
    }

    if (newPassword.length < 8) {
      setMessages(['Password baru harus minimal 8 karakter.']);

      return false;
    }

    if (newConfirmationPassword.length < 8) {
      setMessages(['Konfirmasi password baru harus minimal 8 karakter.']);

      return false;
    }

    return true;
  };

  const passwordChecker = () => {
    const { newPassword, newConfirmationPassword } = userFormData;

    if (newPassword !== newConfirmationPassword) {
      setMessages(['Password baru dan konfirmasi password baru tidak sesuai.']);

      return false;
    }

    return true;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setFormLoading(true);
    resetState();

    if (validateFormField() && passwordChecker()) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const { oldPassword, newPassword } = userFormData;

          return Auth.changePassword(user, oldPassword, newPassword).then(
            () => {
              window.location.replace('https://www.kode.id');
            },
          );
        })
        .catch((err) => {
          const errorMessages = [
            'Incorrect username or password.',
            'Attempt limit exceeded, please try after some time.',
          ];

          if (err.message.includes(errorMessages[0])) {
            setMessages(['Password lama salah']);
          }

          if (err.message.includes(errorMessages[1])) {
            setMessages([
              'Batas maksimum percobaan untuk mengubah password telah melebihi batas. Silakan coba beberapa saat lagi.',
            ]);
          }

          setFormLoading(false);
        });
    } else {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    const userClientSideSession = fetchBrowserCookies();
    document.title = 'Auth Kode ID - Change Password';

    if (
      !userClientSideSession.id
      || !userClientSideSession.email
      || !userClientSideSession.firstName
      || !userClientSideSession.lastName
    ) {
      history.push('/signin');
    } else {
      const fetchUser = async () => {
        const user = await kodeCheckEmail({
          email: userClientSideSession.email,
        });

        if (user.data.data) {
          setSignUpMethod(user.data.data.sign_up_method);
        }
      };

      fetchUser();
    }
  }, [history]);

  return (
    <Layout>
      <div css={containerStyles}>
        {messages.length !== 0 && (
          <div css={snackbarStyles}>
            {messages.map((message) => (
              <span key={message} css={messageStyles}>
                {message}
              </span>
            ))}
          </div>
        )}
        <div css={containerStyles}>
          <img src={kodeLogo} css={logoStyles} alt="KODE-logo" />
          <MainForm
            userFormData={userFormData}
            signUpMethod={signUpMethod}
            onChange={handleChange}
            handleChangePassword={handleChangePassword}
            formLoading={formLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
