/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { jsx } from '@emotion/core';

import { Button, Input } from '../../../forms';
import {
  formContainerStyles,
  formTitleStyles,
  formDescriptionStyles,
  formStyles,
  submitButtonStyles,
  notificationMessageStyles,
  linkStyles,
} from './ConfirmResetPassword.styles';

const ConfirmResetPassword = ({
  userFormData,
  onChange,
  handleConfirmResetPassword,
  formLoading,
  confirmPasswordStatus,
}) => {
  const {
    email,
    verificationCode,
    newPassword,
    newConfirmationPassword,
  } = userFormData;

  return (
    <div css={formContainerStyles}>
      {!confirmPasswordStatus ? (
        <React.Fragment>
          <h1 css={formTitleStyles}>atur ulang password kamu</h1>
          <p css={formDescriptionStyles}>silakan masukkan password baru kamu</p>
          <form css={formStyles} onSubmit={handleConfirmResetPassword}>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              iconName="envelope"
              placeholder="email"
            />
            <Input
              type="text"
              name="verificationCode"
              value={verificationCode}
              onChange={onChange}
              iconName="key"
              placeholder="verification code"
              readOnly
            />
            <Input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={onChange}
              iconName="lock"
              placeholder="new password"
            />
            <Input
              type="password"
              name="newConfirmationPassword"
              value={newConfirmationPassword}
              onChange={onChange}
              iconName="lock"
              placeholder="confirm password"
            />
            <Button styles={submitButtonStyles}>
              {formLoading ? 'menunggu...' : 'setel ulang password'}
            </Button>
          </form>
        </React.Fragment>
      ) : (
        <p css={notificationMessageStyles}>
          Pembaharuan password kamu telah berhasil. Silakan Sign In kembali
          &nbsp;
          <NavLink to="/signin" css={linkStyles}>disini</NavLink>
        </p>
      )}
    </div>
  );
};

ConfirmResetPassword.propTypes = {
  userFormData: PropTypes.shape({
    email: PropTypes.string,
    verificationCode: PropTypes.string,
    newPassword: PropTypes.string,
    newConfirmationPassword: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  handleConfirmResetPassword: PropTypes.func.isRequired,
  formLoading: PropTypes.bool.isRequired,
  confirmPasswordStatus: PropTypes.bool.isRequired,
};

ConfirmResetPassword.defaultProps = {
  userFormData: PropTypes.shape({
    email: '',
    verificationCode: '',
    newPassword: '',
    newConfirmationPassword: '',
  }),
};

export default ConfirmResetPassword;
