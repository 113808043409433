/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { NavLink, useLocation } from 'react-router-dom';
import { jsx } from '@emotion/core';

import MainForm from '../../components/pages/ResetPassword/ResetPassword/MainForm';
import ConfirmResetPassword from '../../components/pages/ResetPassword/ConfirmResetPassword/ConfirmResetPassword';
import {
  authResetPassword,
  authConfirmResetPassword,
} from '../../services/aws';
import { specialCharacterTrimmer } from '../../helpers';
import {
  snackbarStyles,
  messageStyles,
  containerStyles,
  mainLogoStyles,
  linkContainerStyles,
  linkStyles,
} from './ResetPassword.styles';
import kodeLogo from '../../assets/images/logo/kode-logo-dark.png';

const ResetPassword = () => {
  const { search } = useLocation();
  const { code, confirm } = queryString.parse(search);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(false);
  const [confirmPasswordStatus, setConfirmPasswordStatus] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [userFormData, setUserFormData] = useState({
    email: '',
    verificationCode: code,
    newPassword: '',
    newConfirmationPassword: '',
  });
  const [messages, setMessages] = useState([]);
  const {
    email,
    verificationCode,
    newPassword,
    newConfirmationPassword,
  } = userFormData;

  const resetState = () => {
    setMessages([]);
  };

  useEffect(() => {
    document.title = 'Auth Kode ID - Reset Password';
  }, []);

  const handleChange = (e) => {
    e.persist();
    setUserFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const passwordChecker = () => {
    if (newPassword !== newConfirmationPassword) {
      setMessages((prevState) => [
        ...prevState,
        'Password dan konfirmasi password tidak sesuai.',
      ]);

      return false;
    }

    return true;
  };

  const handleResetPassword = (e) => {
    const username = specialCharacterTrimmer(email);

    e.preventDefault();
    setFormLoading(true);
    resetState();
    authResetPassword({
      username,
      setMessages,
      setFormLoading,
      setResetPasswordStatus,
    });
  };

  const handleConfirmResetPassword = (e) => {
    const username = specialCharacterTrimmer(email);

    e.preventDefault();
    setFormLoading(true);
    resetState();

    if (passwordChecker()) {
      authConfirmResetPassword({
        username,
        verificationCode,
        newPassword,
        setMessages,
        setFormLoading,
        setConfirmPasswordStatus,
      });
    } else {
      setFormLoading(false);
      resetState();
    }
  };

  return (
    <React.Fragment>
      <div css={containerStyles}>
        {messages.length !== 0 && (
          <div css={snackbarStyles}>
            {messages.map((message) => (
              <span key={message} css={messageStyles}>
                {message}
              </span>
            ))}
          </div>
        )}
        <img src={kodeLogo} css={mainLogoStyles} alt="kode-logo" />
        {!confirm ? (
          <MainForm
            email={email}
            onChange={handleChange}
            handleResetPassword={handleResetPassword}
            formLoading={formLoading}
            resetPasswordStatus={resetPasswordStatus}
          />
        ) : (
          <ConfirmResetPassword
            userFormData={userFormData}
            onChange={handleChange}
            handleConfirmResetPassword={handleConfirmResetPassword}
            formLoading={formLoading}
            confirmPasswordStatus={confirmPasswordStatus}
          />
        )}
        {!confirmPasswordStatus && (
          <div css={linkContainerStyles}>
            <NavLink to="/signin" css={linkStyles}>
              Kembali ke Halaman Sign In
            </NavLink>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
