import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';

import GoogleButton from './GoogleButton';
import {
  kodeCheckEmail,
  kodeGoogleSignIn,
} from '../../../../../../services/axios';

const GoogleSignIn = ({ redirect, setMessages }) => {
  const googleResponse = async (response) => {
    const {
      googleId,
      tokenId,
      profileObj: { email },
    } = response;

    await kodeCheckEmail({ email })
      .then(() => {
        kodeGoogleSignIn({
          accessToken: tokenId,
          googleId,
          redirect,
        });
      })
      .catch((err) => {
        if (err.response.data.data === null) {
          setMessages([`${email} belum terdaftar.`]);
        }
      });
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_APP_ID}
      buttonText="Google"
      onSuccess={googleResponse}
      onFailure={() => {}}
      cookiePolicy="single_host_origin"
      render={(renderProps) => <GoogleButton onClick={renderProps.onClick} />}
    />
  );
};

GoogleSignIn.propTypes = {
  redirect: PropTypes.shape({
    status: PropTypes.bool,
    productId: PropTypes.string,
  }).isRequired,
  setMessages: PropTypes.func.isRequired,
};

export default GoogleSignIn;
