import { Auth } from 'aws-amplify';

const authResetPassword = async ({
  username,
  setMessages,
  setFormLoading,
  setResetPasswordStatus,
}) => {
  await Auth.forgotPassword(username)
    .then(() => {
      setFormLoading(false);
      setResetPasswordStatus(true);
    })
    .catch((err) => {
      if (err.message === 'Username cannot be empty') {
        setMessages(['Email wajib diisi.']);
      } else {
        setMessages((prevState) => [...prevState, err.message]);
      }

      setFormLoading(false);
    });
};

export default authResetPassword;
