import { Auth } from 'aws-amplify';

const authSignIn = async ({ email, password }) => {
  await Auth.signIn({
    username: email.toLowerCase(),
    password,
  });
};

export default authSignIn;
