/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { jsx } from '@emotion/core';

import MainForm from '../../components/pages/SignUp/MainForm/MainForm';
import { authSignUp } from '../../services/aws';
import {
  kodeCheckEmail,
  kodeSignUp,
  kodeSignUpRollback,
  kodeFacebookSignup,
  kodeGoogleSignup,
} from '../../services/axios';
import {
  containerStyles,
  logoStyles,
  snackbarStyles,
  messageStyles,
  questionContainerStyles,
  questionStyles,
  linkStyles,
} from './SignUp.styles';
import kodeLogo from '../../assets/images/logo/kode-logo-dark.png';
import UserDataConfirmation from '../../components/pages/SignUp/UserDataConfirmation/UserDataConfirmation';
import Layout from '../../components/layout/Layout';

const SignUp = () => {
  const [signUpMethod, setSignUpMethod] = useState('FORM');
  const [
    isShowUserDataConfirmation,
    setShowUserDataConfirmationStatus,
  ] = useState(false);
  // const [setFormSubmittedStatus] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [facebookLoginResponse, setFacebookLoginResponse] = useState({});
  const [googleLoginResponse, setGoogleLoginResponse] = useState({});
  const [userFormData, setUserFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    domicile: '',
    password: '',
    confirmationPassword: '',
  });
  const [messages, setMessages] = useState([]);

  const resetErrorMessage = () => {
    setMessages([]);
  };

  const handleChange = (e) => {
    e.persist();
    setUserFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    document.title = 'Auth Kode ID - Sign Up';
  }, []);

  const validateFormField = ({
    disableEmail,
    disablePhone,
    disableDomicile,
    disablePassword,
  }) => {
    const {
      firstName, email, phoneNumber, domicile, password,
    } = userFormData;

    if (!firstName.replace(/ +(?= )/g, '').trim()) {
      setMessages(['Nama depan wajib diisi.']);

      return false;
    }

    if (!disableEmail) {
      if (
        !email
          .toLowerCase()
          .replace(/ +(?= )/g, '')
          .trim()
      ) {
        setMessages(['Email wajib diisi.']);

        return false;
      }
    }

    if (!disablePhone) {
      if (!phoneNumber) {
        setMessages(['Nomor telepon wajib diisi.']);

        return false;
      }

      if (!phoneNumber.match(/^\d+$/)) {
        setMessages(['Nomor telepon hanya bisa diisi oleh angka saja.']);

        return false;
      }

      if (phoneNumber.length < 10) {
        setMessages(['Nomor telepon harus minimal 10 digit angka.']);

        return false;
      }
    }

    if (!disableDomicile) {
      if (!domicile) {
        setMessages(['Domisili wajib dipilih']);

        return false;
      }
    }

    if (!disablePassword) {
      if (!password) {
        setMessages(['Password wajib diisi.']);

        return false;
      }

      if (password.length < 6) {
        setMessages(['Password harus minimal 6 karakter.']);

        return false;
      }
    }

    return true;
  };

  const passwordChecker = () => {
    const { password, confirmationPassword } = userFormData;

    if (password !== confirmationPassword) {
      setMessages(['Password dan konfirmasi password tidak sesuai.']);

      return false;
    }

    return true;
  };

  const handleConfirmationForm = (e) => {
    e.preventDefault();
    resetErrorMessage();

    if (
      validateFormField({
        disableEmail: false,
        disablePhone: true,
        disableDomicile: true,
        disablePassword: false,
      })
      && passwordChecker()
    ) {
      setShowUserDataConfirmationStatus(true);
    }
  };

  const handleSignUp = async () => {
    let parsedEmail;
    let parsedFirstName;
    let parsedLastName;

    try {
      setFormLoading(true);
      resetErrorMessage();

      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        domicile,
      } = userFormData;

      const isFormValid = validateFormField({
        disableEmail: false,
        disablePhone: false,
        disableDomicile: false,
        disablePassword: false,
      });

      if (isFormValid) {
        parsedEmail = email.toLowerCase().replace(/ +(?= )/g, '').trim();
        parsedFirstName = firstName.replace(/ +(?= )/g, '').trim();
        parsedLastName = lastName.replace(/ +(?= )/g, '').trim();

        // replaces empty last name into "-"
        if (parsedLastName === '') {
          parsedLastName = '-';
        }

        // email should NOT be exist
        try {
          await kodeCheckEmail({ email: parsedEmail });
          setFormLoading(false);
          setMessages([
            `Email ${parsedEmail} sudah pernah terdaftar sebelumnya. Silakan login.`,
          ]);
        } catch (e) {
          // registering user to database
          await kodeSignUp({
            email: parsedEmail,
            firstName: parsedFirstName,
            lastName: parsedLastName,
            phoneNumber,
            domicile,
          });

          // registering user to cognito
          await authSignUp({
            email: parsedEmail,
            password,
          });

          window.location.href = '/process-sign-up-completed?loginWith=manual';
        }
      }
    } catch (e) {
      await kodeSignUpRollback({ email: parsedEmail });
      setFormLoading(false);
      setMessages([
        `Terjadi gangguan teknis [${e.message}]. Silakan coba lagi atau hubungi support@hacktiv8.com`,
      ]);
    }
  };

  const handleFacebookSignUp = async () => {
    const { userID, accessToken } = facebookLoginResponse;
    const {
      firstName, lastName, phoneNumber, domicile,
    } = userFormData;

    setFormLoading(true);
    resetErrorMessage();

    if (
      validateFormField({
        disableEmail: true,
        disablePhone: false,
        disableDomicile: false,
        disablePassword: true,
      })
    ) {
      await kodeFacebookSignup({
        accessToken,
        facebookId: userID,
        firstName,
        lastName,
        phoneNumber,
        domicile,
      }).then(() => {
        window.location.href = `/process-sign-up-completed?ref=${accessToken}&refId=${userID}&loginWith=facebook`;
      });
    } else {
      setFormLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    const { googleId, tokenId } = googleLoginResponse;
    const {
      firstName, lastName, phoneNumber, domicile,
    } = userFormData;

    setFormLoading(true);
    resetErrorMessage();

    if (
      validateFormField({
        disableEmail: true,
        disablePhone: false,
        disableDomicile: false,
        disablePassword: true,
      })
    ) {
      await kodeGoogleSignup({
        accessToken: tokenId,
        googleId,
        firstName,
        lastName,
        phoneNumber,
        domicile,
      }).then(() => {
        window.location.href = `/process-sign-up-completed?ref=${tokenId}&refId=${googleId}&loginWith=google`;
      });
    } else {
      setFormLoading(false);
    }
  };

  return (
    <Layout>
      {messages.length !== 0 && (
        <div css={snackbarStyles}>
          {messages.map((message) => (
            <span key={message} css={messageStyles}>
              {message}
            </span>
          ))}
        </div>
      )}
      <div css={containerStyles}>
        <img src={kodeLogo} css={logoStyles} alt="kode-logo" />
        {!isShowUserDataConfirmation ? (
          <React.Fragment>
            <MainForm
              onChange={handleChange}
              userFormData={userFormData}
              setUserFormData={setUserFormData}
              handleConfirmationForm={handleConfirmationForm}
              setShowUserDataConfirmationStatus={
                setShowUserDataConfirmationStatus
              }
              setSignUpMethod={setSignUpMethod}
              setFacebookLoginResponse={setFacebookLoginResponse}
              setGoogleLoginResponse={setGoogleLoginResponse}
            />
            <div css={questionContainerStyles}>
              <p css={questionStyles}>
                sudah memiliki akun? Silakan&nbsp;
                <NavLink to="/signin" css={linkStyles}>
                  masuk
                </NavLink>
              </p>
            </div>
          </React.Fragment>
        ) : (
          <UserDataConfirmation
            userFormData={userFormData}
            onChange={handleChange}
            signUpMethod={signUpMethod}
            handleSignUp={handleSignUp}
            handleFacebookSignUp={handleFacebookSignUp}
            handleGoogleSignUp={handleGoogleSignUp}
            formLoading={formLoading}
          />
        )}
      </div>
    </Layout>
  );
};

export default SignUp;
