import axios from 'axios';

import { setAccessToken } from '../../managers/auth';

const kodeFacebookSignIn = async ({ accessToken, facebookId, redirect }) => {
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_KODE_API}/login/facebook`,
    method: 'post',
    data: {
      facebook_id: facebookId,
    },
  }).then((res) => {
    const kodeUserId = res.data.user.id;
    const kodeUserEmail = res.data.user.email;
    const kodeUserFirstName = res.data.user.first_name;
    const kodeUserLastName = res.data.user.last_name;

    document.cookie = `kode_user_id=${kodeUserId}; domain=${process.env.REACT_APP_DOMAIN}`;
    document.cookie = `kode_user_email=${encodeURI(kodeUserEmail)}; domain=${
      process.env.REACT_APP_DOMAIN
    }`;
    document.cookie = `kode_user_first_name=${encodeURI(
      kodeUserFirstName,
    )}; domain=${process.env.REACT_APP_DOMAIN}`;
    document.cookie = `kode_user_last_name=${encodeURI(
      kodeUserLastName,
    )}; domain=${process.env.REACT_APP_DOMAIN}`;
    document.cookie = `kode_user_access_token=${res.data.jwt}; domain=${
      process.env.REACT_APP_DOMAIN
    }`;

    setAccessToken(res.data.jwt);

    if (redirect.status) {
      window.location.href = `https://www.kode.id/enroll/${redirect.productId}?et=free_trial`;
    } else {
      window.location.href = res.data.redirect_to;
    }
  });
};

export default kodeFacebookSignIn;
