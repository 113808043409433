import { css } from '@emotion/core';

import layoutBg from '../../assets/images/illustrations/layout-bg.png';
import { colors } from '../../configs';

export const layoutStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.LIGHT_GREY};
  min-height: 100vh;

  @media only screen and (min-width: 1200px) {
    padding: 30px 0;
  }
`;

export const bodyStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    justify-content: space-between;
    align-items: center;
    width: 1024px;
  }
`;

export const leftContainerStyles = css`
  display: none;

  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const logoStyles = css`
  width: 160px;
  height: auto;
`;

export const backgroundImageStyles = css`
  width: 500px;
  height: 460px;
  margin: 10px 0 0 0;
  background-image: url(${layoutBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const rightContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 15px;

  @media only screen and (min-width: 1200px) {
    width: auto;
    padding: 0;
  }
`;
