export const setAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem('accessToken');
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken');

  return accessToken;
};
