import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import FacebookButton from './FacebookButton';
import {
  kodeCheckEmail,
  kodeFacebookSignIn,
} from '../../../../../../services/axios';

const FacebookSignIn = ({
  id,
  setSignUpMethod,
  setFacebookLoginResponse,
  setUserFormData,
  setShowUserDataConfirmationStatus,
}) => {
  const responseFacebook = (response) => {
    const {
      accessToken,
      userID,
      name,
      email,
    } = response;

    kodeCheckEmail({ email })
      .then(() => {
        kodeFacebookSignIn({
          accessToken,
          facebookId: userID,
          redirect: {
            status: false,
          },
        });
      })
      .catch(() => {
        setSignUpMethod('FACEBOOK');
        setFacebookLoginResponse(response);
        setUserFormData((prevState) => ({
          ...prevState,
          firstName: name,
          lastName: '-',
        }));
        setShowUserDataConfirmationStatus(true);
      });
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields="name, email, picture"
      callback={responseFacebook}
      render={(renderProps) => (
        <FacebookButton id={id} onClick={renderProps.onClick} />
      )}
    />
  );
};

FacebookSignIn.propTypes = {
  id: PropTypes.string.isRequired,
  setSignUpMethod: PropTypes.func.isRequired,
  setFacebookLoginResponse: PropTypes.func.isRequired,
  setUserFormData: PropTypes.func.isRequired,
  setShowUserDataConfirmationStatus: PropTypes.func.isRequired,
};

export default FacebookSignIn;
