import axios from 'axios';

const kodeGoogleSignUp = async ({
  accessToken,
  googleId,
  firstName,
  lastName,
  phoneNumber,
  domicile,
}) => {
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_KODE_API}/register/google`,
    method: 'post',
    data: {
      google_id: googleId,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      domicile,
    },
  });
};

export default kodeGoogleSignUp;
