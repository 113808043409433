import { css } from '@emotion/core';

import { colors } from '../../../../configs';

export const formContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  margin: 30px 0 0 0;
  padding: 35px 15px;
  border-radius: 10px;
  background-color: ${colors.WHITE};
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 1024px) {
    max-width: 500px;
  }

  @media only screen and (min-width: 1200px) {
    margin: 0;
    width: 470px;
    max-width: unset;
    padding: 50px 20px;
  }
`;

export const contentStyles = css`
  width: 100%;

  @media only screen and (min-width: 1200px) {
    width: 415px;
  }
`;

export const formTitleStyles = css`
  text-align: center;
  line-height: 1.2;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 20px;
  color: ${colors.KODE_CHARCOAL};

  @media only screen and (min-width: 1200px) {
    font-size: 24px;
  }
`;

export const formDescriptionStyles = css`
  margin: 15px 0 0 0;
  text-align: center;
  line-height: 1.85;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.KODE_CHARCOAL};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const formStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 30px 0 0 0;
`;

export const inputContainerStyles = css`
  width: 100%;

  @media only screen and (min-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const inputStyles = css`
  margin: 0 15px 0 0;
`;

export const submitButtonStyles = css`
  margin: 15px 0 0 0;
`;

export const socialMediaSeparatorContainerStyles = css`
  position: relative;
  width: 100%;
  margin: 30px 0 0 0;
`;

export const socialMediaSeparatorStyles = css`
  width: 100%;
  height: 2px;
  background-color: ${colors.LIGHT_GREY_2};
`;

export const socialMediaSeparatorDescriptionStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 15px;
  white-space: nowrap;
  background-color: ${colors.WHITE};
  font-size: 14px;
  color: ${colors.KODE_CHARCOAL};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const socialMediaContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0 0 0;
`;

export const termsAndConditionContainerStyles = css`
  width: 100%;
  margin: 30px 0 0 0;

  @media only screen and (min-width: 1200px) {
    width: 275px;
  }
`;

export const termsAndConditionTextStyles = css`
  text-align: center;
  word-break: break-word;
  font-size: 12px;
  color: ${colors.KODE_CHARCOAL};
  line-height: 2;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const termsAndConditionLinkStyles = css`
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 700;
  color: ${colors.KODE_TOPAZ};
`;
