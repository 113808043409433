import axios from 'axios';

const kodeSignUpRollback = ({
  email,
}) => axios({
  url: `${process.env.REACT_APP_KODE_API}/register/email/rollback?email=${email}`,
  method: 'delete',
});

export default kodeSignUpRollback;
