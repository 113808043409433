/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Button, Input } from '../../../forms';
import { FacebookSignIn, GoogleSignIn } from './SocialMedia';
import {
  formContainerStyles,
  contentStyles,
  formTitleStyles,
  formDescriptionStyles,
  formStyles,
  inputContainerStyles,
  inputStyles,
  submitButtonStyles,
  socialMediaSeparatorContainerStyles,
  socialMediaSeparatorStyles,
  socialMediaSeparatorDescriptionStyles,
  socialMediaContainerStyles,
  termsAndConditionContainerStyles,
  termsAndConditionTextStyles,
  termsAndConditionLinkStyles,
} from './MainForm.styles';

const MainForm = ({
  onChange,
  userFormData,
  setUserFormData,
  handleConfirmationForm,
  setShowUserDataConfirmationStatus,
  setSignUpMethod,
  setFacebookLoginResponse,
  setGoogleLoginResponse,
}) => {
  const {
    firstName,
    lastName,
    email,
    password,
    confirmationPassword,
  } = userFormData;

  return (
    <div css={formContainerStyles}>
      <div css={contentStyles}>
        <h1 css={formTitleStyles}>mulai belajar</h1>
        <p css={formDescriptionStyles}>dapatkan akses ke ratusan course berkualitas</p>
        <form css={formStyles} onSubmit={handleConfirmationForm}>
          <div css={inputContainerStyles}>
            <Input
              type="text"
              containerStyles={inputStyles}
              name="firstName"
              value={firstName}
              onChange={onChange}
              iconName="pencil"
              placeholder="nama depan"
            />
            <Input
              type="text"
              name="lastName"
              value={lastName}
              onChange={onChange}
              iconName="pencil"
              placeholder="nama belakang"
            />
          </div>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            iconName="envelope"
            placeholder="email"
          />
          <div css={inputContainerStyles}>
            <Input
              type="password"
              containerStyles={inputStyles}
              name="password"
              value={password}
              onChange={onChange}
              iconName="lock"
              placeholder="password"
            />
            <Input
              type="password"
              name="confirmationPassword"
              value={confirmationPassword}
              onChange={onChange}
              iconName="lock"
              placeholder="konfirmasi password"
            />
          </div>
          <Button id="sign-up-button" styles={submitButtonStyles}>
            daftar sekarang
          </Button>
          <div css={socialMediaSeparatorContainerStyles}>
            <div css={socialMediaSeparatorStyles} />
            <p css={socialMediaSeparatorDescriptionStyles}>atau daftar melalui social media</p>
          </div>
          <div css={socialMediaContainerStyles}>
            <FacebookSignIn
              id="facebook-button"
              setSignUpMethod={setSignUpMethod}
              setFacebookLoginResponse={setFacebookLoginResponse}
              setUserFormData={setUserFormData}
              setShowUserDataConfirmationStatus={setShowUserDataConfirmationStatus}
            />
            <GoogleSignIn
              id="google-button"
              setSignUpMethod={setSignUpMethod}
              setGoogleLoginResponse={setGoogleLoginResponse}
              setUserFormData={setUserFormData}
              setShowUserDataConfirmationStatus={setShowUserDataConfirmationStatus}
            />
          </div>
          <div css={termsAndConditionContainerStyles}>
            <p css={termsAndConditionTextStyles}>
              nama yang terdaftar akan
              tertera pada sertifikat course.
              Dengan mendaftar, Saya telah menyetujui
              &nbsp;
              <a
                href="https://www.kode.id/pages/syarat-dan-ketentuan"
                target="_blank"
                rel="noopener noreferrer"
                css={termsAndConditionLinkStyles}
              >
                terms and condition
              </a>
                &nbsp;dan&nbsp;
              <a
                href="https://www.kode.id/pages/kebijakan-privasi"
                target="_blank"
                rel="noopener noreferrer"
                css={termsAndConditionLinkStyles}
              >
                privacy policy
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

MainForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  userFormData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmationPassword: PropTypes.string,
  }).isRequired,
  setUserFormData: PropTypes.func.isRequired,
  handleConfirmationForm: PropTypes.func.isRequired,
  setShowUserDataConfirmationStatus: PropTypes.func.isRequired,
  setSignUpMethod: PropTypes.func.isRequired,
  setFacebookLoginResponse: PropTypes.func.isRequired,
  setGoogleLoginResponse: PropTypes.func.isRequired,
};

export default MainForm;
