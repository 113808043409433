/** @jsx jsx */
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { jsx } from '@emotion/core';

import Layout from '../../components/layout/Layout';
import kodeLogo from '../../assets/images/logo/kode-logo-dark.png';
import MainForm from '../../components/pages/SignIn/MainForm/MainForm';
import { useQuery } from '../../hooks';
import { authSignIn } from '../../services/aws';
import { kodeSignIn } from '../../services/axios';
import {
  containerStyles,
  snackbarStyles,
  messageStyles,
  logoStyles,
  questionContainerStyles,
  questionStyles,
  linkStyles,
} from './SignIn.styles';

const SignIn = () => {
  const query = useQuery();
  const [redirect, setRedirect] = useState({
    status: false,
    productId: '',
  });
  const [formLoading, setFormLoading] = useState(false);
  const [userFormData, setUserFormData] = useState({
    email: '',
    password: '',
  });
  const [messages, setMessages] = useState([]);
  const email = userFormData.email.toLowerCase();
  const { password } = userFormData;

  const resetErrorMessage = () => {
    setMessages([]);
  };

  const handleChange = (e) => {
    e.persist();
    setUserFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const validateFormField = () => {
    if (!email) {
      setMessages(['Email wajib diisi.']);

      return false;
    }

    if (!password) {
      setMessages(['Password wajib diisi.']);

      return false;
    }

    return true;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    resetErrorMessage();

    if (validateFormField()) {
      await authSignIn({
        email,
        password,
      }).then(() => {
        Auth.currentSession().then((res) => {
          const accessToken = res.accessToken.jwtToken;

          kodeSignIn(accessToken, email, redirect);
        });
      })
        .catch((err) => {
          if (err.message === 'Incorrect username or password.') {
            setMessages(['Email atau Password salah.']);
          } else if (err.message === 'User does not exist.') {
            setMessages([`${email} tidak terdaftar.`]);
          } else {
            setMessages(['Email atau Password salah.']);
          }

          setFormLoading(false);
        });
    } else {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    const handleRedirectedMessage = () => {
      document.title = 'Auth Kode ID - Sign In';
      const queryStringFlag = query.get('flag');
      const queryStringProductId = query.get('product_id');

      if (queryStringFlag === 'preview-free-trial') {
        setMessages([
          'Kamu harus Masuk atau Daftar terlebih dahulu untuk melihat Free Preview.']);
        setRedirect({ status: true, productId: queryStringProductId });
      }
    };

    handleRedirectedMessage();
  }, [query]);

  return (
    <Layout>
      {messages.length !== 0 && (
        <div css={snackbarStyles}>
          {messages.map((message) => (
            <span key={message} css={messageStyles}>
              {message}
            </span>
          ))}
        </div>
      )}
      <div css={containerStyles}>
        <img src={kodeLogo} css={logoStyles} alt="KODE-logo" />
        <MainForm
          userFormData={userFormData}
          onChange={handleChange}
          redirect={redirect}
          handleSignIn={handleSignIn}
          formLoading={formLoading}
          setMessages={setMessages}
        />
        <div css={questionContainerStyles}>
          <p css={questionStyles}>
            belum memiliki akun?&nbsp;
            <NavLink to="/signup" css={linkStyles}>
              daftar sekarang
            </NavLink>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default SignIn;
