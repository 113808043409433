import { css } from '@emotion/core';

import { colors } from '../../../../configs';

export const formContainerStyles = css`
  width: 100%;
  margin: 15px 0 0 0;
  padding: 30px;
  border-radius: 5px;
  background-color: ${colors.WHITE};

  @media only screen and (min-width: 1024px) {
    width: 560px;
    margin: 30px 0 0 0;
  }
`;

export const formTitleStyles = css`
  text-align: center;
  line-height: 1.2;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 24px;
  color: ${colors.BLACK};

  @media only screen and (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const formDescriptionStyles = css`
  margin: 10px 0 0 0;
  text-align: center;
  line-height: 1.2;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.BLACK};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const formStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 40px 0 0 0;
`;

export const submitButtonStyles = css`
  margin: 30px 0 0 0;
`;

export const notificationMessageStyles = css`
  margin: 10px 0 0 0;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.BLACK};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const linkStyles = css`
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.KODE_PURPLE};
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;
