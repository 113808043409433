/** @jsx jsx */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';

import { Input, Button } from '../../../forms';
import {
  formContainerStyles,
  contentStyles,
  formTitleStyles,
  notificationContainerStyles,
  notificationStyles,
  formStyles,
  submitButtonStyles,
} from './MainForm.styles';

const MainForm = ({
  userFormData,
  signUpMethod,
  formLoading,
  onChange,
  handleChangePassword,
}) => {
  const [isDisabled, setDisabledStatus] = useState(false);
  const [notification, setNotification] = useState('');
  const { oldPassword, newPassword, newConfirmationPassword } = userFormData;

  useEffect(() => {
    const message = [
      'perubahan ini hanya akan mengubah password saat kamu sign in, tidak akan mengubah password sosial media kamu.',
      'kamu tidak perlu melakukan perubahan password, karena pendaftaran dilakukan melalui sosial media',
    ];

    if (signUpMethod === 'ALL') {
      setNotification(message[0]);
    }

    if (signUpMethod === 'SOCIAL_MEDIA') {
      setDisabledStatus(true);
      setNotification(message[1]);
    }
  }, [signUpMethod]);

  return (
    <div css={formContainerStyles}>
      <div css={contentStyles}>
        <h1 css={formTitleStyles}>ubah password</h1>
        {signUpMethod !== 'EMAIL' && (
          <div css={notificationContainerStyles}>
            <p css={notificationStyles}>{notification}</p>
          </div>
        )}
        <form css={formStyles} onSubmit={handleChangePassword}>
          <Input
            type="password"
            name="oldPassword"
            value={oldPassword}
            onChange={onChange}
            iconName="lock"
            placeholder="password lama"
            readOnly={isDisabled}
          />
          <Input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={onChange}
            iconName="lock"
            placeholder="password baru"
            readOnly={isDisabled}
          />
          <Input
            type="password"
            name="newConfirmationPassword"
            value={newConfirmationPassword}
            onChange={onChange}
            iconName="lock"
            placeholder="konfirmasi password baru"
            readOnly={isDisabled}
          />
          <Button styles={submitButtonStyles} disabled={isDisabled}>
            {formLoading ? 'menunggu...' : 'perbarui password'}
          </Button>
        </form>
      </div>
    </div>
  );
};

MainForm.propTypes = {
  userFormData: PropTypes.shape({
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
    newConfirmationPassword: PropTypes.string,
  }).isRequired,
  signUpMethod: PropTypes.string.isRequired,
  formLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
};

export default MainForm;
