/** @jsx jsx */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';

import { Button, Input, SelectBox } from '../../../forms';
import { indonesianCities } from '../../../../configs';
import {
  formContainerStyles,
  contentStyles,
  formTitleStyles,
  fullNameStyles,
  formDescriptionStyles,
  formStyles,
  submitButtonStyles,
} from './UserDataConfirmation.styles';

const CertificateNameConfirmation = ({
  userFormData,
  onChange,
  signUpMethod,
  handleSignUp,
  handleFacebookSignUp,
  handleGoogleSignUp,
  formLoading,
}) => {
  const [options, setOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);

  const {
    firstName, lastName, phoneNumber, domicile,
  } = userFormData;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (signUpMethod === 'FORM') {
      handleSignUp();
    }

    if (signUpMethod === 'FACEBOOK') {
      handleFacebookSignUp();
    }

    if (signUpMethod === 'GOOGLE') {
      handleGoogleSignUp();
    }
  };

  useEffect(() => {
    const parsedOptions = indonesianCities.map((city) => ({
      label: city,
      value: city,
    }));

    setOptions(parsedOptions);
  }, []);

  return (
    <div css={formContainerStyles}>
      <div css={contentStyles}>
        <h1 css={formTitleStyles}>
          satu langkah lagi,&nbsp;
          <span css={fullNameStyles}>{`${firstName} ${lastName}`}</span>
          !
        </h1>
        <p css={formDescriptionStyles}>
          Demi kelancaran pelaporan Penyelesaian Kelas, Anda wajib untuk memasukkan informasi
          nama yang identik (sama persis) dengan Kartu Tanda Penduduk (KTP) anda & nama yang
          tertera di akun Prakerja anda (apabila anda adalah peserta Prakerja)
        </p>
        {/* <p css={formDescriptionStyles}>
          Mohon periksa dan pastikan kembali nama depan dan nama belakang yang
          anda daftarkan sudah benar, agar proses pelaporan dapat berjalan
          dengan lancar.
        </p> */}
        <p css={formDescriptionStyles}>
          Nama yang terdaftar akan digunakan dalam SERTIFIKAT. Setelah proses
          pendaftaran selesai, nama yang tertera di sertifikat TIDAK DAPAT
          DIUBAH.
        </p>
        <form css={formStyles} onSubmit={handleSubmit}>
          <Input
            type="text"
            name="firstName"
            value={firstName}
            onChange={onChange}
            iconName="pencil"
            placeholder="nama depan"
          />
          <Input
            type="text"
            name="lastName"
            value={lastName}
            onChange={onChange}
            iconName="pencil"
            placeholder="nama belakang"
          />
          <Input
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={onChange}
            pattern="[0-9]*"
            iconName="phone"
            placeholder="nomor telepon"
          />
          <SelectBox
            options={options}
            name="domicile"
            value={domicile}
            onChange={onChange}
            placeholder="pilih domisili"
          />
          <Button id="sign-up-button" styles={submitButtonStyles}>
            {formLoading ? 'menunggu...' : 'ok, data saya sudah benar'}
          </Button>
        </form>
      </div>
    </div>
  );
};

CertificateNameConfirmation.propTypes = {
  userFormData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    domicile: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  signUpMethod: PropTypes.oneOf(['FORM', 'FACEBOOK', 'GOOGLE']).isRequired,
  handleSignUp: PropTypes.func.isRequired,
  handleFacebookSignUp: PropTypes.func.isRequired,
  handleGoogleSignUp: PropTypes.func.isRequired,
  formLoading: PropTypes.bool.isRequired,
};

export default CertificateNameConfirmation;
