import axios from 'axios';

import { specialCharacterTrimmer } from '../../helpers';

const kodeSignUp = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  domicile,
}) => axios({
  url: `${process.env.REACT_APP_KODE_API}/register/email`,
  method: 'post',
  data: {
    email,
    cognito_username: specialCharacterTrimmer(email),
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    domicile,
  },
});

export default kodeSignUp;
