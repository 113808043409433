/* eslint-disable camelcase */
import { Auth } from 'aws-amplify';

const authConfirmResetPassword = async ({
  username,
  verificationCode,
  newPassword,
  setFormLoading,
  setMessages,
  setConfirmPasswordStatus,
}) => {
  const code = verificationCode;
  const new_password = newPassword;

  await Auth.forgotPasswordSubmit(username, code, new_password)
    .then(() => {
      setFormLoading(false);
      setConfirmPasswordStatus(true);
    })
    .catch((err) => {
      setMessages((prevState) => [...prevState, err.message]);
      setFormLoading(false);
    });
};

export default authConfirmResetPassword;
