import { css } from '@emotion/core';

import { colors } from '../../../../../../configs';

export const facebookButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid ${colors.FACEBOOK_BLUE};
  border-radius: 50%;
  background-color: ${colors.FACEBOOK_BLUE};
  outline: none;
  cursor: pointer;
`;

export const iconStyles = css`
  font-size: 16px;
  color: ${colors.WHITE};
`;
