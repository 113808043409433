import {
  ChangePassword,
  ResetPassword,
  SignIn,
  SignUp,
  ProcessSignUpCompleted,
} from '../pages';

export default [
  {
    isExact: true,
    path: '/change-password',
    component: ChangePassword,
  },
  {
    isExact: true,
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    isExact: true,
    path: '/signin',
    component: SignIn,
  },
  {
    isExact: true,
    path: '/signup',
    component: SignUp,
  },
  {
    isExact: true,
    path: '/process-sign-up-completed',
    component: ProcessSignUpCompleted,
  },
];
