/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';

import { Icon } from '../../../../../commons';
import { facebookButtonStyles, iconStyles } from './FacebookButton.styles';

const FacebookButton = ({ id, onClick }) => (
  <button id={id} type="button" css={facebookButtonStyles} onClick={onClick}>
    <Icon type="BRAND" styles={iconStyles} name="facebook-f" />
  </button>
);

FacebookButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FacebookButton;
