import { css } from '@emotion/core';

import { colors } from '../../configs';

export const snackbarStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 5px 30px;
  background-color: ${colors.KODE_TOPAZ};
  z-index: 9999;
`;

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const messageStyles = css`
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: ${colors.WHITE};

  &:after {
    content: ', ';
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
`;

export const logoStyles = css`
  width: 115px;
  height: auto;

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

export const questionContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
`;

export const questionStyles = css`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.KODE_CHARCOAL};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const linkStyles = css`
  text-transform: capitalize;
  font-weight: 700;
  color: ${colors.KODE_TOPAZ};
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;
