/** @jsx jsx */

import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { jsx } from '@emotion/core';
import Layout from '../../components/layout/Layout';
import SuccessPage from '../../components/pages/SignUp/SuccessPage/SuccessPage';

import {
  kodeFacebookSignIn,
  kodeGoogleSignIn,
} from '../../services/axios';

import {
  containerStyles,
} from './ProcessSignUpCompleted.styles';

const useQuery = () => (
  new URLSearchParams(useLocation().search)
);

const handleGoogleSignIn = async (tokenId, googleId) => {
  await kodeGoogleSignIn({
    accessToken: tokenId,
    googleId,
    redirect: {
      status: false,
      productId: '',
    },
  });
};

const handleFacebookSignIn = async (accessToken, userId) => {
  await kodeFacebookSignIn({
    accessToken,
    facebookId: userId,
    redirect: {
      status: false,
      productId: '',
    },
  });
};

const ProcessSignUpCompleted = () => {
  const [isSuccessPage, setIsSuccessPage] = useState(false);
  const query = useQuery();

  useEffect(() => {
    document.title = 'Auth Kode ID - Process Sign Up Completed';
  }, []);

  useLayoutEffect(() => {
    const queryLogin = query.get('loginWith');
    const refToken = query.get('ref');
    const refId = query.get('tokenId');

    if (queryLogin === 'google') {
      handleGoogleSignIn(refToken, refId);
    } else if (queryLogin === 'facebook') {
      handleFacebookSignIn(refToken, refId);
    } else { // manual login here
      setIsSuccessPage(true);
    }
  }, [query]);

  return (
    <Layout>
      {!isSuccessPage ? (
        <React.Fragment>
          <div css={containerStyles}>
            <h1> Redirect Page ... </h1>
          </div>
        </React.Fragment>
      ) : (
        <SuccessPage />
      )}
    </Layout>
  );
};

export default ProcessSignUpCompleted;
