/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import Amplify from 'aws-amplify';
import TagManager from 'react-gtm-module';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Global } from '@emotion/core';

import routeConfigs from './routes/route.config';
import awsconfig from './aws-exports';
import { globalStyles } from './configs';

const App = () => {
  const appInit = () => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
    Amplify.configure(awsconfig);
  };

  // const liveChatFrontApp = () => {
  //   window.FrontChat('init', {
  //     chatId: '569d8d036eb3d7b4689bcb6047974fba',
  //     useDefaultLauncher: true,
  //   });
  // };


  useEffect(() => {
    appInit();
    // liveChatFrontApp();
  }, []);

  return (
    <>
      <Global styles={globalStyles} />
      <Switch>
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        {routeConfigs.map((route) => (
          <Route
            key={route.path}
            exact={route.isExact}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
      <div id="whatsapp-widget" style={{ display: 'block' }}>
        <span>
          <a href="https://api.whatsapp.com/send?phone=622180675787&text=Halo,%20saya%20mau%20informasi%20lebih%20lanjut%20tentang%20KODE" target="_blank">
            <img
              src="https://s3.ap-southeast-1.amazonaws.com/statics.hacktiv8.com/whatsapp.png"
              width="60"
              height="60"
              style={{
                position: 'fixed',
                bottom: '10px',
                right: '10px',
                zIndex: '999',
              }}
            />
          </a>
        </span>
      </div>
    </>
  );
};

export default App;
