import axios from 'axios';

const kodeFacebookSignUp = async ({
  accessToken,
  facebookId,
  firstName,
  lastName,
  phoneNumber,
  domicile,
}) => {
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_KODE_API}/register/facebook`,
    method: 'post',
    data: {
      facebook_id: facebookId,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      domicile,
    },
  });
};

export default kodeFacebookSignUp;
