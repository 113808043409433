/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';

import { Icon } from '../../../../../commons';
import { facebookButtonStyles, iconStyles } from './FacebookButton.styles';

const FacebookButton = ({ onClick }) => (
  <button type="button" css={facebookButtonStyles} onClick={onClick}>
    <Icon type="BRAND" styles={iconStyles} name="facebook-f" />
  </button>
);

FacebookButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FacebookButton;
